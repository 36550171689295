import React, { useContext, useEffect, useRef, useState } from "react";
import { BlockInvalidChar } from "../../../utility/helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { notify } from "../../../Common/toast";
import AddCustomer from "./AddCustomer";
import AddView from "./AddView";
import { UserAccessContext, UserContext } from "../../Application";
import ViewHistory from "./ViewHistory";
import { Dropdown } from 'react-bootstrap';


export const FilterPrescentageWithExpiry = (cellObj) => {
  const {
    column: { filterValue, setFilter, preFilteredRows, id },
  } = cellObj;
  const [value, setValue] = useState("");
  const [sslvalue, setSslvalue] = useState("");

  const [open, setOpen] = useState(false);
  const [disable, setDisabled] = useState(true);
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");


  const ref1 = useRef(null)
  const wrapperRef = useRef(null)
  const userAccess = useContext(UserAccessContext)
  const options = [
    { key: "Above or Equal To 66%", value: "Above or Equal To 66%" },
    { key: "Below 66%", value: "Below 66%" },
  ];
  useEffect(() => {
    let obj = {};
    obj[id] = ref1;
    cellObj?.refObj.push(obj);
    cellObj?.setRefObj([...cellObj?.refObj]);
  }, [id]);
  // Render a multi-select box

  const toggleDropdown = (value) => {
    setOpen(value)
  }


  useEffect(() => {
    if (minValue && maxValue) {
      if (minValue > maxValue) {
        setDisabled(true)
      }
      else if (maxValue < minValue) {
        setDisabled(true)
      }
      else {
        setDisabled(false)
      }
    }
  }, [minValue, maxValue]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        toggleDropdown(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    const handleScrolling = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        toggleDropdown(false)

      }
    }
    window.addEventListener("wheel", handleScrolling);
    return () => {
      document.removeEventListener("wheel", handleScrolling);
    };
  }, [wrapperRef]);

  const handleSelectionChange = (itemValue) => {
    if (itemValue == 'range') {
      let val = `${minValue}-${maxValue}`
      if (id == 'expiry_percent') {
        setValue(val)
        setTimeout(function () {
          document.getElementsByClassName("ssl_percent-select-dropq1")[0].value = ""
          document.getElementsByClassName("expiry_percent-select-dropq1")[0].value = val
          setSslvalue("")
        }, 0);

      } else {
        setSslvalue(val)
        setTimeout(function () {
          document.getElementsByClassName("expiry_percent-select-dropq1")[0].value = ""
          document.getElementsByClassName("ssl_percent-select-dropq1")[0].value = val
          setValue("")
        }, 0);
      }
    } else {
      if (id == 'expiry_percent') {
        console.log("if idd ->", id)
        setValue(itemValue)
        setTimeout(function () {
          // document.getElementsByClassName("ssl_percent-select-dropq1")[0].value = ""
          document.getElementsByClassName("expiry_percent-select-dropq1")[0].value = itemValue

          setSslvalue("")
        }, 0);

      } else {
        console.log("else idd ->", id)

        setSslvalue(itemValue)
        setTimeout(function () {
          document.getElementsByClassName("ssl_percent-select-dropq1")[0].value = itemValue
          // document.getElementsByClassName("expiry_percent-select-dropq1")[0].value = ""
          setValue("")
        }, 0);
      }
    }


    cellObj.filterData(itemValue == 'range' ? `${minValue}-${maxValue}` : itemValue || undefined, id, true);
    if (itemValue) {
      cellObj.refObj?.forEach((key) => {
        if (Object.keys(key)[0] !== id && key[Object.keys(key)[0]].current) {
          // if (key[Object.keys(key)[0]].current.state)
          //   key[Object.keys(key)[0]].current.state.value = "";
          // else {
          //   key[Object.keys(key)[0]].current.value = "";
          // }
        }
      });
    }
    toggleDropdown(false);
  }

  setTimeout(function () {
    console.log("id -->", id)

    console.log("value -->", value)
    console.log("ssl val -->", sslvalue)
  }, 1000);



  return (
    <div class="input-edit-mode position-relative input-with-loader relative" ref={wrapperRef} >
      {/* <input id="tpm_provisions_eur" className={`qw1 input-edit-mode ${id}-select-dropq1`} type="text" ref={ref1} value={value} onClick={() => toggleDropdown(!open)} /> */}
      <input id="tpm_provisions_eur" className={`qw1 input-edit-mode ${id}-select-dropq1`} type="text" ref={ref1}  onClick={() => toggleDropdown(!open)} />
      {open && <div className="list-viw" >
        {options.map((option, i) => {
          return (
            <>
              <div className="item-view" key={i} onClick={() => handleSelectionChange(option.key)}>{option.value}</div>
              {i == options.length - 1 ?
                <div className="item-view" key={i + 2} >
                  <div className="item-view-custom" >
                    <div><input type="number" className="input-edit-mode border-0 input-bg input-slim-more" style={{ paddingLeft: "2px" }} placeholder="min" value={minValue} onChange={(e) => setMinValue(e.target.value)} /></div>
                    <div><input placeholder="max" value={maxValue} type="number" onChange={(e) => setMaxValue(e.target.value)} style={{ paddingLeft: "2px" }} className="input-edit-mode border-0 input-bg input-slim-more" /></div>
                    <div>
                      <button type="button" disabled={disable} className="btn btn-primary btn-slim btn-slim-more" onClick={() => handleSelectionChange('range')}>Go</button>
                    </div>
                  </div>
                </div>
                : ''}
            </>
          )
        })}
      </div>}
    </div>
  );
}


export const FilterPrescentageData = (cellObj) => {
  const {
    column: { filterValue, setFilter, preFilteredRows, id },
  } = cellObj;
  const [value, setValue] = useState("")
  const ref = useRef(null)
  const userAccess = useContext(UserAccessContext)
  const options = [
    { key: "Above or Equal To 66%", value: "Above or Equal To 66%" },
    { key: "Below 66%", value: "Below 66%" },
  ];
  useEffect(() => {
    let obj = {};
    obj[id] = ref;
    cellObj?.refObj.push(obj);
    cellObj?.setRefObj([...cellObj?.refObj]);
  }, [id]);
  // Render a multi-select box
  return (
    <select
      disabled={userAccess?.pomdata?.data?.accessId === "1" ? true : false}
      className="qw1 input-edit-mode btn-slim"
      ref={ref}
      onChange={(e) => {
        setValue(e.target.value)
        cellObj.filterData(e.target.value || undefined, id, true);
        if (e.target.value) {
          console.log(cellObj)
          cellObj.refObj?.forEach((key) => {
            if (Object.keys(key)[0] !== id && key[Object.keys(key)[0]].current) {
              // if (key[Object.keys(key)[0]].current.state)
              //   key[Object.keys(key)[0]].current.state.value = "";
              // else {
              //   key[Object.keys(key)[0]].current.value = "";
              // }
            }
          });
        }
      }}
    >
      <option value="">Select</option>
      {options.map((option, i) => (
        <option key={i} value={option.key}>
          {option.value}
        </option>
      ))}
      <option value="">
        <input type="number" placeholder="min" style={{ backgroundColor: "red" }} />
        {/* <input type="number" placeholder="max" /> */}
      </option>
    </select>
  );
}

export const PomEditCell = (cellObj) => {
  const { column, row, value, adviceList, actions } = cellObj.cell;
  const [isAddCustomerModalOpen, setAddCustomerModalOpen] = useState(false);
  const [isAddViewModalOpen, setAddViewModalOpen] = useState(false);
  const [viewHistoryModalOpen, setViewHistoryModalOpen] = useState(false);
  const [val, setVal] = useState(value);
  const [isSelection, setIsSelection] = useState(false);
  const userAccess = useContext(UserAccessContext)
  const handleCellChange = (e) => {
    console.log("e.targert -->1", e.target.value)
    if (e.target) {
      setVal(e.target.value);
      setIsSelection(true);
      if (
        column.id === "local_deal_completion_month" ||
        column.id === "nextStepId" ||
        column.id === "advice" ||
        column.id === "actionsId"
      ) {
        cellObj.updateCellData(row, column, e.target.value);
      }
    } else {
      setVal(e);
    }
  };

  const NumberField = () => {
    return (
      <input
        type="number"
        disabled={userAccess?.pomdata?.data?.accessId === "1" ||row.original.id==="" ? true : false}
        // disabled={userAccess.accessId[0] === "1"||row.original.id===""  ? true : false}
        min={["qty_to_offer", "discount", "price_per_cu", "price_per_case", "qtyToOfferCs"].includes(column.id) ? 0 : -999999999999999999}
        autoFocus
        value={val === null ? " " : val}
        onChange={(e) => {
          if (
            (column.id !== "qty_to_offer" && column.id !== "qtyToOfferCs") ||
            ((column.id == "qty_to_offer" || column.id == "qtyToOfferCs") &&
              (e.target.value ? parseFloat(e.target.value) : 0) <=
              (column.id == "qty_to_offer" ? (row.original.qty
                ? parseFloat(row.original.qty)
                : 0) : (row.original.qtyCs
                  ? parseFloat(row.original.qtyCs)
                  : 0)))
          ) {
            handleCellChange(e);
          } else {
            notify({
              message: "Quantity Available for Sales should be lesser than Stock Quantity",
              type: "warn",
            });
          }
        }}
        onBlur={(e) => {
          if (
            (column.id !== "qty_to_offer" && column.id !== "qtyToOfferCs") ||
            ((column.id == "qty_to_offer" || column.id == "qtyToOfferCs") &&
              (e.target.value ? parseFloat(e.target.value) : 0) <=
              (column.id == "qty_to_offer" ? (row.original.qty
                ? parseFloat(row.original.qty)
                : 0) : (row.original.qtyCs
                  ? parseFloat(row.original.qtyCs)
                  : 0)))
          ) {
            cellObj.updateCellData(row, column, val);
          } else {
            notify({
              message: "Quantity Available for Sales should be lesser than Stock Quantity",
              type: "warn",
            });
          }
        }}
        onKeyDown={BlockInvalidChar}
      />
    );
  };

  const TextField = () => {
    console.log(userAccess)
    return (
      <input
        disabled={userAccess?.pomdata?.data?.accessId === "1"||row.original.id==="" ? true : false}
        // disabled={userAccess.accessId[0] === "1"||row.original.id==="" ? true : false}
        type="text"
        autoFocus
        value={val === null ? " " : val}
        onChange={handleCellChange}
        onBlur={() => cellObj.updateCellData(row, column, val)}
      />
    );
  };

  const DateSelection = () => {
    return (
      <DatePicker
        disabled={userAccess?.pomdata?.data?.accessId === "1"||row.original.id==="" ? true : false}
        // disabled={userAccess.accessId[0] === "1"||row.original.id==="" ? true : false}
        portalId="root-portal"
        dateFormat="dd/MM/yyyy"
        selected={val ? new Date(val) : null}
        value={val === null ? " " : val}
        onSelect={handleCellChange} //when day is clicked
        onChange={(date) => cellObj.updateCellData(row, column, date)} //only when value has changed
      />
    );
  };

  const addCustomersModal = () => {
    setAddCustomerModalOpen(true);
  };
  const customerAddition = () => {
    return <button type="button">sd</button>;
  };
  const nextStep = () => {
    return (
      <select
        disabled={userAccess?.pomdata?.data?.accessId === "1" ||row.original.id===""? true : false}
        // disabled={userAccess.accessId[0] === "1"||row.original.id===""  ? true : false}
        className="qw2 input-edit-mode btn-slim width-100"
        onChange={handleCellChange}
        autoFocus
        value={val ?? value}>
        <option value="" selected>
          Select
        </option>
        {cellObj.nextStepList.map((value) => {
          return <option value={value.k}>{value.v}</option>;
        })}
      </select>
    );
  };

  const StatusSelection = () => {
    return (
      <select
        disabled={userAccess?.pomdata?.data?.accessId === "1"||row.original.id==="" ? true : false}
        // disabled={userAccess.accessId[0] === "1"||row.original.id===""  ? true : false}
        className="input-edit-mode btn-slim"
        onChange={(e) => {
          handleCellChange(e);
        }}
        autoFocus
        value={val ? val : value}
      >
        {cellObj.dealMonths?.map((item) => {
          return <option value={item.v}>{item.v}</option>;
        })}
      </select>
    );
  };
  const adviceSelection = () => {
    return (
      <select
        disabled={userAccess?.pomdata?.data?.accessId === "1"||row.original.id==="" ? true : false}
        // disabled={userAccess.accessId[0] === "1"||row.original.id===""  ? true : false}
        className="input-edit-mode btn-slim"
        onChange={(e) => {
          handleCellChange(e);
        }}
        autoFocus
        value={val ? val : value}
      >
        <option value="" selected>
          Select
        </option>
        {cellObj.adviceList?.map((item) => {
          return <option value={item.v}>{item.v}</option>;
        })}
      </select>
    );
  };

  const actionSelection = () => {
    return (
      <select
        disabled={userAccess?.pomdata?.data?.accessId === "1" ||row.original.id===""? true : false}
        // disabled={userAccess.accessId[0] === "1"||row.original.id===""  ? true : false}
        className="input-edit-mode btn-slim"
        onChange={(e) => {
          handleCellChange(e);
        }}
        autoFocus
        value={val ? val : value}
      >
        <option value="" selected>
          Select
        </option>
        {cellObj.actions?.map((item) => {
          return <option value={item.id}>{item.action_name}</option>;
        })}
      </select>
    );
  };
  let FieldType;
  switch (column.id) {
    case "actionsId":
      FieldType = actionSelection;
      break;
    case "central_deal_quantity_in_zun":
      FieldType = NumberField;
      break;
    case "qty_to_offer":
      FieldType = NumberField;
      break;
    case "qtyToOfferCs":
      FieldType = NumberField;
      break;
    case "discount":
      FieldType = NumberField;
      break;
    case "price_per_cu":
      FieldType = NumberField;
      break;
    case "price_per_case":
      FieldType = NumberField;
      break;
    case "floorpr":
      FieldType = NumberField;
      break;
    case "expiry_date":
      FieldType = DateSelection;
      //FieldType = TextField;
      break;
    case "local_deal_completion_month":
      FieldType = StatusSelection;
      break;
    case "nextStepId":
      FieldType = nextStep;
      break;
    case "add_customer":
      FieldType = customerAddition;
      break;
    case "batch_code":
      FieldType = customerAddition;
      break;
    case "view_history":
      FieldType = customerAddition;
      break;
    case "advice":
      FieldType = adviceSelection;
      break;
    default:
      FieldType = TextField;
  }
  const user = useContext(UserContext);
  return (
    <>
      {column.id === "add_customer"&& row.original.id!=="" && (
        <>
          <button
            type="button"
            title="add/view customer"
            className="btn btn-secondary btn-slim text-center"
            onClick={() => addCustomersModal()}
          >
            Add/View Customers
          </button>
          <AddCustomer
            key={isAddCustomerModalOpen}
            show={isAddCustomerModalOpen}
            rowdata={[cellObj.cell.row.allCells]}
            onHide={() => setAddCustomerModalOpen(false)}
            record={row.original}
            reLoad={cellObj.reLoad}
            tabKey={cellObj.tabKey}
          />

        </>
      )}
      {column.id === "batch_code" && row.original.id!=="" && (
        <>
          <button
            type="button"
            className="btn btn-secondary btn-slim text-center"
            onClick={() => setAddViewModalOpen(true)}
          >
            View Batch code
          </button>
          <AddView
            key={isAddViewModalOpen}
            show={isAddViewModalOpen}
            rowdata={[cellObj.cell.row.allCells]}
            onHide={() => setAddViewModalOpen(false)}
            record={row.original}
            reLoad={cellObj.reLoad}
          />

        </>
      )}
      {column.id === "view_history"&& row.original.id!==""  && (
        <>
          <button
            type="button"
            className="btn btn-secondary btn-slim text-center"
            onClick={() => setViewHistoryModalOpen(true)}
            title=" View History"
          >
            View History
          </button>
          {viewHistoryModalOpen && <ViewHistory
            key={viewHistoryModalOpen}
            show={viewHistoryModalOpen}
            rowdata={[cellObj.cell.row.allCells]}
            onHide={() => setViewHistoryModalOpen(false)}
            record={row.original}
            reLoad={cellObj.reLoad}
            materialno={cellObj.cell.row.original.mrdr_id}
            descriptionId={cellObj.cell.row.original.descriptionId}
            countryId={cellObj.cell.row.original.countryId}
            plantCodeId={cellObj.cell.row.original.plantCodeId}
            email={user.email}
            cellactions={cellObj.nextStepList}
          />}

        </>

      )}
      {(cellObj.clickedIndex === row.index &&
        cellObj.clickedCell === column.id && (
          <div className="edit-cell">{FieldType()}</div>
        )) || (column.id !== "batch_code" &&
          <div
            className="readonly"
            title={value ? (column.id === "type_pal") ? Math.round(value) : value : "Click to edit cell value"}
            onClick={() => cellObj.handleClickEditCell(cellObj.cell)}
            style={{ width: "100%", height: "100%" }}
          >
            {column.id === "actionsId"
              ? cellObj.actions?.filter((i) => i.id == value)[0]?.action_name ||
              ""
              : column.id === "nextStepId"
                ? cellObj.nextStepList?.filter((i) => i.k == value)[0]?.v || ""
                : column.id === "type_pal" ? Math.round(val) : val}
          </div>
        )}
    </>
  );
};
export const DateRangeColumnFilter = (cellObj) => {
  const {
    column: { filterValue, setFilter, preFilteredRows, id },
  } = cellObj;
  const [value, setValue] = useState("")
  const ref = useRef(null)
  const options = [
    { key: "lesser-than-0", value: "less than 0" },
    { key: "0-12wks", value: "0 to 12 weeks" },
    { key: "12-24wks", value: "12 to 24 weeks" },
    { key: "greater-than-24wks", value: "greater than 24 weeks" },
  ];
  useEffect(() => {
    let obj = {};
    obj[id] = ref;
    cellObj?.refObj.push(obj);
    cellObj?.setRefObj([...cellObj?.refObj]);
  }, [id]);
  return (
    <select
      className="input-edit-mode btn-slim"
      ref={ref}
      onChange={(e) => {
        setValue(e.target.value)
        cellObj.filterData(e.target.value || undefined, id, true);
        if (e.target.value) {
          cellObj.refObj?.forEach((key) => {
            if (Object.keys(key)[0] !== id && key[Object.keys(key)[0]].current) {
              // if (key[Object.keys(key)[0]].current.state)
              //   key[Object.keys(key)[0]].current.state.value = "";
              // else {
              //   key[Object.keys(key)[0]].current.value = "";
              // }
            }
          });
        }
      }}
      onBlur={(e) => {
        if (cellObj.column.id !== "bbd" && cellObj.column.id !== "hah_date") {
          setValue("")
          cellObj.filterData("", id, true);
        }
      }}
    >
      <option value="">Select</option>
      {options.map((option, i) => (
        <option key={i} value={option.key}>
          {option.value}
        </option>
      ))}
    </select>
  );
}

export const DecimalToNumWithPercentage = (cellObj) => {

  const { column, row, value } = cellObj.cell;

  return (
    <>
      {value ? `${Math.floor(value)}%` : ""}
    </>
  );
};
